<script>
  export let size = undefined
</script>

<svg
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
  height={size}
  width={size}
  {...$$restProps}
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
  />
</svg>
